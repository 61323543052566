import React , {useState} from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import btc from '../../assets/images/coin/btc.png'
import eth from '../../assets/images/coin/eth.png'
import tet from '../../assets/images/coin/tet.png'
import xpr from '../../assets/images/coin/xpr.png'
import dai from '../../assets/images/coin/dai.png'
import uni from '../../assets/images/coin/uni.png'
import avax from '../../assets/images/coin/avax.png'
import link from '../../assets/images/coin/link.png'
import ape from '../../assets/images/coin/ape.png'
import flow from '../../assets/images/coin/flow.png'
import chz from '../../assets/images/coin/chz.png'
import xtz from '../../assets/images/coin/xtz.png'
import dot from '../../assets/images/coin/dot.png'
import comp from '../../assets/images/coin/comp.png'
import ksm from '../../assets/images/coin/ksm.png'
import sol from '../../assets/images/coin/sol.png'
import grt from '../../assets/images/coin/grt.png'
import {useKraken} from '../../Hooks/useKraken';

import './styles.scss';

import {Link} from 'react-router-dom';

Crypto01.propTypes = {
    
};

function Crypto01(props) {
    const kraken = useKraken();
    const [tab, setTab] = useState('crypto');

    const [dataCrytoTab] = useState([
        {title: 'Crypto'},
        {title: 'DeFi'},
        {title: 'NFT'},
        {title: 'Polkadot'},
        {title: 'Solana'},
    ])
    // critical
    // success
    const [dataCrytoContent] = useState([
        {
            name: 'Bitcoin',
            unit: 'XBT/EUR',
            icon: btc,
            category: 'crypto'
        },
        {
            name: 'Ethereum',
            unit: 'ETH/EUR',
            icon: eth,
            category: 'crypto'
        },
        {
            name: 'Tether',
            unit: 'USDT/EUR',
            icon: tet,
            category: 'crypto'
        },
        {
            name: 'Ripple',
            unit: 'XRP/EUR',
            icon: xpr,
            category: 'crypto'
        },
        {
            name: 'Dai',
            unit: 'DAI/EUR',
            icon: dai,
            category: 'defi'
        },
        {
            name: 'Uniswap',
            unit: 'UNI/EUR',
            icon: uni,
            category: 'defi'
        },
        {
            name: 'Avalanche',
            unit: 'AVAX/EUR',
            icon: avax,
            category: 'defi'
        },
        {
            name: 'Chainlink',
            unit: 'LINK/EUR',
            icon: link,
            category: 'defi'
        },
        {
            name: 'ApeCoin',
            unit: 'APE/EUR',
            icon: ape,
            category: 'nft'
        },
        {
            name: 'Flow',
            unit: 'FLOW/EUR',
            icon: flow,
            category: 'nft'
        },
        {
            name: 'Chiliz',
            unit: 'CHZ/EUR',
            icon: chz,
            category: 'nft'
        },
        {
            name: 'Tezos',
            unit: 'XTZ/EUR',
            icon: xtz,
            category: 'nft'
        },
        {
            name: 'Polkadot',
            unit: 'DOT/EUR',
            icon: dot,
            category: 'polkadot'
        },
        {
            name: 'Chainlink',
            unit: 'LINK/EUR',
            icon: link,
            category: 'polkadot'
        },
        {
            name: 'Compound',
            unit: 'COMP/EUR',
            icon: comp,
            category: 'polkadot'
        },
        {
            name: 'Kusama',
            unit: 'KSM/EUR',
            icon: ksm,
            category: 'polkadot'
        },
        {
            name: 'Solana',
            unit: 'SOL/EUR',
            icon: sol,
            category: 'solana'
        },
        {
            name: 'Tether',
            unit: 'USDT/EUR',
            icon: tet,
            category: 'solana'
        },
        {
            name: 'Chainlink',
            unit: 'LINK/EUR',
            icon: link,
            category: 'solana'
        },
        {
            name: 'The Graph',
            unit: 'GRT/EUR',
            icon: grt,
            category: 'solana'
        },
    ])

    const relDiff = (a, b) => 100 * Math.abs( ( a - b ) / ( (a+b)/2 ) );       
    return (
        <section className="crypto starsBG" data-aos="fade-up" data-aos-duration="1000">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="crypto__main">
                        <Tabs>
                            <TabList>
                                {dataCrytoTab.map((idx, index) => (<Tab key={index} onClick={e=>setTab(idx.title.toLowerCase())}>{idx.title}</Tab>))}
                            </TabList>

                            {dataCrytoTab.map((data, index) => (
                                <TabPanel key={index}>
                                    <div className="content-inner">
                                        {
                                           kraken.pairs && dataCrytoContent.filter(item=>item.category === tab).map((data, index) => (
                                                <div key={index} className={`crypto-box ${data.active}`}>
                                                    <div className="top">
                                                        <Link to="#">
                                                        <img src={data.icon} alt="TheMoonkey" />
                                                        <span>{data.name}</span>
                                                        <span className="unit">{data.unit}</span></Link>
                                                    </div>
                                                    <h6 className="price">{Number(kraken.pairs[data.unit]?.p[0]).toLocaleString('en-US')}</h6>
                                                    <div className="bottom">
                                                        <p>Trades in 24h: {Number(kraken.pairs[data.unit]?.t[1]).toLocaleString('en-US')}</p>
                                                        <p className={`sale success`}>{relDiff(Number(kraken.pairs[data.unit]?.t[1]), Number(kraken.pairs[data.unit]?.t[0])).toFixed(2)}%</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </TabPanel>
                            ))}
                        </Tabs> 
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default Crypto01;