import React from 'react';

import Banner from '../components/banner/Banner';
import Crypto01 from '../components/crypto/Crypto01';
import Work from '../components/work/Work';
import About01 from '../components/about/About01';
import ExchangeSecure from '../components/ExchangeSecure';
import dataPartner from '../assets/fake-data/data-partner';
import Mobile from '../components/Platforms/Mobile';
import Desktop from '../components/Platforms/Desktop';


function Home(props) {
    return (
        <div className='home-1'>

            <Banner data={dataPartner} />

            <Crypto01 />

            {/* <Coinlist01 dataCoin={dataCoin} /> */}

            <About01 />

            <ExchangeSecure />
            
            <Work />

            <Mobile />

            <Desktop />
        </div>
    );
}

export default Home;