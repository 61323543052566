import React , {useState} from 'react';

import img from '../../assets/images/Illustrations/Mobile-half.svg'


function Mobile() {

    const [dataBlock] = useState(
        {
            heading: 'Mobile',
            desc : `Provide fast and seamless mobile experience to your customers. TheMoonkey’s software is designed to be easily integrated with your website or mobile app. Our white label solutions are mobile friendly, so you can offer your users a smooth experience from anywhere in the world.`,
        }
    );

    return (
        <section className="mobile starsBG">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="mobile__image">
                            <img src={img} alt="TheMoonkey" />
                        </div>
                    </div>

                    <div className="col-xl-6 col-md-12">
                        <div className="__content" data-aos="fade-up" data-aos-duration="1000">
                            <h3 className="heading">{dataBlock.heading}</h3>
                            <p className="fs-20 decs">
                                {dataBlock.desc}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Mobile;