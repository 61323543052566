import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

export const useKraken = () => {
    const [pairs, setPairs] = useState({})

    const publicWebSocketURL = 'wss://ws.kraken.com/'
    const publicWebSocketSubscriptionMsg = '{ "event": "subscribe", "subscription": { "name": "ticker"}, "pair": [ "XBT/EUR","ETH/EUR","USDT/EUR","XRP/EUR","DAI/EUR","UNI/EUR","AVAX/EUR","LINK/EUR","APE/EUR","FLOW/EUR","CHZ/EUR","XTZ/EUR","DOT/EUR","COMP/EUR","KSM/EUR","SOL/EUR","GRT/EUR" ] }'
    const { sendMessage, lastMessage, readyState } = useWebSocket(publicWebSocketURL)
    useEffect(() => {
        sendMessage(publicWebSocketSubscriptionMsg)
    }, [])
   
    useEffect(() => {
        if (lastMessage) {
            const response = JSON.parse(String(lastMessage.data))

            if (response?.[3]) {
                setPairs((p) => {
                    p[response[3]] = response[1]
                    return p
                })
            }
        }
    }, [lastMessage])
   
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
    }[readyState]
   
    return { pairs, connectionStatus }
}