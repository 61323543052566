import React , {useState} from 'react';

import img from '../../assets/images/Illustrations/Desktop.svg'


function Desktop() {

    const [dataBlock] = useState(
        {
            heading: 'Desktop',
            desc : `We understand that you are busy and you want to stay focused on your business. Hence, we ensure that everything on our platform as simple and easy as possible. We take care of all the details so you can focus on what's important – making money. Our white labels are well integrated for desktop, so you can start processing transactions right away.`,
        }
    );

    return (
        <section className="desktop starsBG">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="__content" data-aos="fade-up" data-aos-duration="1000">
                            <h3 className="heading">{dataBlock.heading}</h3>
                            <p className="fs-20 decs">
                                {dataBlock.desc}
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="desktop__image">
                            <img src={img} alt="TheMoonkey" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Desktop;