import React , {useState} from 'react';
import exchange from '../../assets/images/Illustrations/exchange.svg'
import secure from '../../assets/images/Illustrations/secure.svg'

function ExchangeSecure() {

    const [dataBlock] = useState(
        {
            secure: {
                img: secure,
                title: 'Secure',
                text: 'Our high security measures ensure that your data is safe from prying eyes. <br />TheMoonkey has a team of experienced professionals in Fintech and Blockchain who have a strong belief that the Cryptocurrency industry will shape the future of the financial world. <br /><br />We use the latest encryption technologies to protect your data from unauthorized access. In addition, we use a variety of security measures to make sure that your data is never lost and we are always expanding our capabilities to meet the needs of our customers.'
            },
            exchange: {
                img: exchange,
                title: 'Exchange',
                text: 'TheMoonkey offers a technological edge for all necessary crypto activities! <br />Get your cultivated cryptocurrency exchange solution which is focused on super-fast and secure performance. <br /><br />TheMoonkey can help you set up all the necessary tools and resources you need to get started. Quickly setup your personalized crypto exchange project or create your own wallet-exchange. Manage the global trading of cryptocurrencies and blockchain-based digital assets easily.'
            }
        }
    )

    return (
        <section className="exchangeSecure">
            <div className="container">
                <div className="row">
                    {Object.values(dataBlock).map((item, index)=>(
                        <div className="col-md-12 m-auto block-text row my-4 align-items-center innerBlock" key={index}>
                            <div className="col-xl-9 col-md-12" key={index}>
                                <h3 className="heading">{item.title}</h3>
                                <p dangerouslySetInnerHTML={{__html: item.text}} />
                            </div>
                            <div className="col-xl-3 col-md-12 text-center" key={index}>
                                <img src={item.img} alt={`TheMoonkey ${item.title}`} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ExchangeSecure;