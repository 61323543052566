import React , { useState ,useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo/logo-dark.svg'
import themoonkey from '../../assets/images/themoonkey/themoonkey.svg'
import telegram from '../../assets/images/icon/telegram.svg'
import email from '../../assets/images/icon/email.svg'

function Footer(props) {

    const [productLink] = useState([
        {
            title: 'Spot',
            path: '/spot'
        },
        {
            title: 'Inverse Perpetual',
            path: '#'
        },
        {
            title: 'USDT Perpetual',
            path: '#'
        },
        {
            title: 'Exchange',
            path: '#'
        },
        {
            title: 'Launchpad',
            path: '#'
        },
        {
            title: 'Binance Pay',
            path: '#'
        },
    ]);
    const [servicesLink] = useState([
        {
            title: 'Buy Crypto',
            path: '#'
        },
        {
            title: 'Markets',
            path: '#'
        },
        {
            title: 'Tranding Fee',
            path: '#'
        },
        {
            title: 'Affiliate Program',
            path: '#'
        },
        {
            title: 'Referral Program',
            path: '#'
        },
        {
            title: 'API',
            path: '#'
        },
    ]);


    const [listSocial] = useState([
        {
            icon: 'icon-facebook-f',
            path: '#'
        },
        {
            icon: 'icon-instagram',
            path: '#'
        },
        {
            icon: 'icon-youtube',
            path: '#'
        },
        {
            icon: 'icon-twitter',
            path: '#'
        },
    ])
    

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (

        <footer className="footer style-2">
            <div className="container">
                <div className="footer__main">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="info">
                            <Link to="/" className="logo dark"><img src={logo} alt="" /><img src={themoonkey} alt="" className='themoonkey' /></Link>
                            <h6>Let's talk! 🤙</h6>
                            <ul className="list">
                                <li><a href="mailto:info@themoonkey.com"><img src={email} alt="TheMoonkey on email" />info@themoonkey.com</a></li>
                                <li><a href="https://themoonkey.t.me/" target="_blank"><img src={telegram} alt="TheMoonkey on telegram" />@TheMoonkey</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="footer-contact">
                            <h5>Contact Us</h5>
                            <p>Any questions? wanna receive a demo? feel free to contact us today!</p>
                            <form action="#">
                                <input type="text" name="full_name" placeholder="Enter your name" required="" />
                                <input type="email" name="email" placeholder="Enter your email" required="" />
                                <button type="submit" className="btn-action">Submit</button>
                            </form>
                            <ul className="list-social d-none">
                                {listSocial.map((data,idx) => (
                                    <li key={idx}>
                                        <Link to={data.path}><span className={data.icon}></span></Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="footer__bottom">
                    <p>©2022 TheMoonkey.com. All rights reserved. Terms of Service | Privacy Terms</p>
                </div>
            </div>
            {isVisible && <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>}
        </footer>
    );
}

export default Footer;