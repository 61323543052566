import React , {useState} from 'react';
import PropTypes from 'prop-types';
import themoonkey from '../../assets/images/themoonkey/themoonkey.svg'

import Button from '../button';



Banner.propTypes = {
    data: PropTypes.array,
};

function Banner(props) {


    const {data} = props;

    const [dataBlock] = useState(
        {
            title: 'Fly your crypto business to the Moon',
            desc : 'TheMoonkey is fully loaded with all the relevant tools and functions offering white label solutions where you can run your own successful crypto exchange, NFT marketplace, or crypto staking platform in just a few clicks. Our well-integrated white labels can help you launch your business much faster than usual and at the same time save you tens of thousands in development costs. With us, you can build your own, personalized crypto brand easily. With our software, your crypto currency dreams are finally within reach!',
            title2: 'Our Partners'
        }
    );
    return (
        <section className="banner starsBG">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__content">
                            <h2 className="title">{dataBlock.title}</h2>
                            <p className="fs-20 desc">
                                {dataBlock.desc}
                            </p>
                            {/* <Button title='Get started now' path='#' /> */}
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__image">
                        <img src={themoonkey} alt="TheMoonkey" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;