import React , {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import WhatIsThemoonkey from '../../assets/images/Illustrations/WhatIsThemoonkey.svg'

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import Button from '../button';

About01.propTypes = {
    
};

function About01(props) {
    const [dataBlock] = useState(
        {
            heading: 'What Is TheMoonkey',
            desc : '<strong>TheMoonkey</strong> services are specially designed for entrepreneurs who want to start their own business in the cryptocurrency world but don’t want to spend thousands of hours in development. With our multipurpose solution, you can launch in a matter of days, without having to worry about the technical aspects.',
        }
    )
    const [dataList] = useState([
        {
            text: 'Quick launching, swift performance: TheMoonkey’s white label solution can be launched much faster than a custom-built platform. This is because all the major components have already been developed and only need to be integrated with your brand.'
        },
        {
            text: 'On the other hand TheMoonkey’s software is optimized to perform on high speed for your end customers. Whether you are looking to start a new cryptocurreny business or just improve your current one, Moonkey is the perfect choice.'
        },
    ])
    return (
        <section className="about starsBG">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div className="about_image">
                        <img className="img-main" src={WhatIsThemoonkey} alt="TheMoonkey" />                   
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div
                    className="about__content"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    >
                    <h3 className="heading">{dataBlock.heading}</h3>
                    <p className="fs-20 decs" dangerouslySetInnerHTML={{__html: dataBlock.desc}}/>
                    <ul className="list">

                        {
                            dataList.map((data,idx)=> (
                                <li key={idx}>
                                    <p className="text">
                                    {data.text}
                                    </p>
                                </li>
                            ))
                        }

                    </ul>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default About01;