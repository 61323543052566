import React , {useState} from 'react';
import PropTypes from 'prop-types';
import line from '../../assets/images/icon/connect-line.png'

import img1 from '../../assets/images/icon/Comparison.png';
import img2 from '../../assets/images/icon/Wallet.png';
import img3 from '../../assets/images/icon/Mining.png';
import img4 from '../../assets/images/icon/Cloud.png';

import {Link} from 'react-router-dom';

function Work() {

    const [options] = useState([
        {
            title: 'Crypto<br />Exchange Software',
            img: img1
        },
        {
            title: 'Crypto<br />Staking Platform',
            img: img2
        },
        {
            title: 'NFT<br />Marketplace',
            img: img3
        },
        {
            title: 'Assistance in applying <br />for crypto license',
            img: img4
        },
    ])

    return (
        <section className="work starsBG">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block-text center">
                            <h3 className="heading">Multipurpose</h3>
                            <p className="fs-20 desc">TheMoonkey offers a wide range of software for crypto currency projects and services</p>
                        </div>

                        <div className="work__main">
                            {options.map((idx, id) => (
                                <div className="work-box" key={id}>
                                    <div className="image">
                                        <img src={idx.img} alt="TheMoonkey" />
                                    </div>
                                    <div className="content">
                                        <Link to="#" className="title" dangerouslySetInnerHTML={{__html: idx.title}}></Link>
                                    </div>
                                    <img className="line" src={line} alt="TheMoonkey" />
                                </div>
                            ))}
                        </div>
                        <div className="block-text center">
                            <p className='fs-16 m-5'>
                                Lock up crypto holdings and obtain rewards/ earn interest; create easy-to-use platform for buying and selling cryptocurrencies and NFTs. Moonkey is here to provide you with all the tools and resources you need to succeed in the industry. Contact us today to learn more about our white label solutions!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </section>
    );
}

export default Work;