import React , { useState , useEffect } from 'react';

import { Link , NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import './styles.scss';
import logo from '../../assets/images/logo/logo.svg';
import logodark from '../../assets/images/logo/logo-dark.svg';
import avt from '../../assets/images/avt/avt-01.jpg';
import DarkMode from './DarkMode';

// import Button from '../button';



const Header = () => {

    const [scroll, setScroll] = useState(false);
        useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };

    
    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
    };

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="header__body d-flex justify-content-center">
                            <div className="header__left">
                                <div className="logo">
                                <NavLink to='/' className="light">
                                    <img src={logo} alt="TheMoonkey" />
                                </NavLink>
                                <NavLink to='/' className="dark">
                                    <img src={logodark} alt="TheMoonkey" />
                                </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
       
    );
}

export default Header;